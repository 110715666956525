<template>
  <div class="relative mt-16" m="x-8" max-w="290">
    <div class="font-500" text="2xl #121212">
      {{ t('key.home.wheretonext.title') }}
    </div>
    <div mt="2" font="300" text="base #808080">
      {{ t('key.home.wheretonext.desc') }}
    </div>
    <div ref="el" mt="8" w="full" overflow-x="auto" class="popular_destination">
      <div class="space-x-4" flex="~ items-center">
        <NuxtLink
          v-for="item in popularCities" :key="item.countryCode"
          :to="{
            path: `/${locale}/rank`,
            query: { ...route.query, destinationName: item.destinationName, destinationId: item.destinationId, countryCode: item.countryCode, tab_key: 1 },
          }"
          class="azgo-haptics-feedback relative" cursor="pointer"
          @click="trackData(item)"
        >
          <van-image
            class="align-middle"
            :src="item.img"
            :width="176"
            :height="176"
            :radius="16"
            fit="cover"
          />
          <div rounded="4" class="absolute bottom-0" w="full" h="full" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.38%, rgba(0, 0, 0, 0.8) 100%);"></div>
          <div class="absolute bottom-3 truncate" text="base white" ml="4">
            {{ item.destinationName }}
          </div>
        </NuxtLink>
      </div>
    </div>
    <div v-if="!arrivedState.right" class="azgo-hover-feedback absolute bottom-1/4 right--6 shadow-[0px_0.87px_15px_0px_#71717159]" flex="~ items-center justify-center" w="12" h="12" rounded="full" bg="white" cursor="pointer" @click="x += width / 3 * 2">
      <NuxtIcon name="my-icon:navback" rotate="180" text="xl #121212" />
    </div>
    <div v-if="!arrivedState.left" class="azgo-hover-feedback absolute bottom-1/4 left--6 shadow-[0px_0.87px_15px_0px_#71717159]" flex="~ items-center justify-center" w="12" h="12" rounded="full" bg="white" cursor="pointer" @click="x -= width / 3 * 2">
      <NuxtIcon name="my-icon:navback" text="xl #121212" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AttrDestinationDto } from '~/apis/back/types'
import { ENUM_EventType } from '~/enums'
import type { TopCityClicksEvent } from '~/types/event-tracking'

const el = ref<HTMLElement | null>(null)
const route = useRoute()

const { x, arrivedState } = useScroll(el, { behavior: 'smooth' })
const { width } = useElementSize(el)

const { t } = useI18n()
const { locale } = useLocale()

const commonStore = useCommonStore()

const popularCities = computed(() =>
  commonStore.commonData.cityInfo?.recommendDestinationList,
)

function trackData(item: AttrDestinationDto) {
  trackEvent<TopCityClicksEvent>({
    event: ENUM_EventType.TopCityClicks,
    click_city_id: `${item.destinationId}`,
    click_city: item.destinationName ?? '',
  })
}
</script>

<style>
.popular_destination::-webkit-scrollbar {
  display: none;
}
</style>
