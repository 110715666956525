<template>
  <div mt="16" mx="8">
    <div text="#121212 2xl" font="500">
      {{ t('key.home.recommend.title') }}
    </div>
    <!-- <van-tabs v-if="!showLoading" v-model:active="active" mt="2" class="intel-tabs--desktop" animated shrink>
      <van-tab v-for="item in recommendList" :key="item.category" :title="t(`key.home.recommend.tab.${item.category?.replace(/-/g, '').toLowerCase()}`)" :name="item.category">
        <DesktopIntelRecommendItem :data="item.recommendList" />
      </van-tab>
    </van-tabs> -->
    <DesktopIntelRecommendItem v-if="!showLoading" :data="recommendList[0].recommendList" />

    <template v-else>
      <ElSkeleton :loading="!!showLoading">
        <template #template>
          <div>
            <!-- <div flex="~" mt="4" gap="4">
              <ElSkeletonItem v-for="item in 5" :key="item" variant="h3" w="20!" />
            </div> -->
            <div flex="~" justify="center" mt="4" gap="4">
              <div v-for="item in 4" :key="item" w="25%" b="1 solid #00000014" rounded="4" overflow="hidden">
                <ElSkeletonItem variant="image" w="full!" h="54!" />
                <div flex="~ col" gap="2" mt="2" p="4">
                  <ElSkeletonItem variant="h3" w="50%!" />
                  <ElSkeletonItem variant="text" w="40%!" />
                  <div flex="~" justify="end">
                    <ElSkeletonItem variant="h3" w="40%!" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ElSkeleton>
    </template>
  </div>
</template>

<script lang="ts" setup>
const userStore = useUserStore()
const { t } = useI18n()
const {
  recommendList,
  // active,
  loading,
  fetchIntelRecommand,
} = useIntelRecommand()
const showLoading = computed(() => recommendList.value.length <= 1 && loading)
fetchIntelRecommand()
watch(() => userStore.userInfo.currency, () => {
  fetchIntelRecommand()
})
</script>

<style lang="scss">
.intel-tabs--desktop {
  --van-tabs-bottom-bar-color: var(--tc-color-primary);
  --van-tab-active-text-color: var(--tc-color-primary);
  --van-tabs-bottom-bar-height: 4px;
  --van-tab-font-size: 16px;
  --van-tabs-line-height: 60px;
  --van-font-bold: 500;

  .van-tabs__line {
    border-radius: 0px;
  }

  .van-tab--shrink {
    padding: 0;
    margin-right: 32px;
  }
}
</style>
