<template>
  <div grid="~ cols-4 gap-x-4 gap-y-4" mt="8">
    <div v-for="item in shownRecommendList" :key="item.azgoId" b="1 solid #00000014" rounded="4" overflow="hidden" flex-shrink="0" flex="~ col" hover="bg-#F6F6F6" class="azgo-haptics-feedback relative" @click="clickCard(item, ENUM_CARD_FROM.HOME_RECOMMEND)">
      <div class="relative">
        <van-image h="54" :src="item.coverImageUrl" fit="cover" style="vertical-align: middle;" w="full" flex="shrink-0" />
        <div class="absolute bottom-0" w="full" h="full" style=" background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.67%, #000000 100%);"></div>
        <!-- <div
          class="absolute" top="4" left="4" bg="#00000033" rounded="1" h="5" px="1" font="300" text="xs white" flex="~ items-center" style="backdrop-filter: blur(4px)"
        >
          {{ item.parentLabelNameList?.[0] }}
        </div> -->

        <div class="absolute bottom-2 truncate" w="full" px="2" text="sm #FFFFFF">
          <div mt="1" flex="~ items-center">
            <template v-if="item.rating">
              <NuxtIcon name="attr-icon:star" text="sm" style="color: var(--tc-color-primary);" />
              <span text="#00C885 sm" ml="1">{{ item.rating }}</span>
              <!-- <van-divider vertical style="padding: 0.1rem; margin: 0 0.25rem;" /> -->
            </template>
            <!-- <span text="sm white" font="300">{{ item.subLabelNameList?.[0] }}</span> -->
          </div>
        </div>
      </div>
      <div px="4" py="2" flex="1 ~ col justify-between">
        <div>
          <div text="#121212 base" font="500" line-clamp="2">
            {{ item.title }}
          </div>
          <div v-if="item.countryName || item.cityName" mt="2" text="#808080 xs" font="300" flex="~ items-center" bg="#F6F6F6" rounded="1" px="2" py="1" w="fit">
            {{ item.countryName }}
            <template v-if="item.cityName && item.countryName !== item.cityName">
              <van-divider vertical style="padding: 0.1rem 0; margin: 0 0.25rem;" />
              {{ item.cityName }}
            </template>
          </div>
        </div>
        <div v-if="item.minPriceRecords?.[0]?.minPrice" mt="5" font="500" text="xl #121212 right">
          <span text="xs #B2B2B2" font="300">{{ t('key.attraction.detail.price.from') }}</span> {{ getCurrencyPrice(item.minPriceRecords?.[0]?.minPrice) }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="(shownRecommendList?.length ?? 0) < (data?.length ?? 0)" text="center">
    <button class="azgo-haptics-feedback hover:bg-#0000000A" cursor="pointer" mt="8" rounded="2" px="13.5" h="12" b="2 solid #121212" text="base #121212" @click="loadMore">
      {{ t('key.home.recommend.viewmore') }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import type { SearchDTO } from '~/apis/back/types'
import { ENUM_CARD_FROM } from '~/enums'

const { data } = defineProps({
  data: {
    type: Array as PropType<SearchDTO[]>,
  },
})

const { getCurrencyPrice, clickCard } = useSearchResult()

const { t } = useI18n()
// const { loadMore, pageNum } = useIntelRecommand()
const pageNum = ref(1)
function loadMore() {
  pageNum.value++
}

// 假分页, 只显示前8个
const shownRecommendList = computed(() => data?.slice(0, pageNum.value * 8))
</script>

<style>

</style>
