<template>
  <div v-if="!!recentViewedList.length" class="relative mt-16" m="x-8" max-w="290">
    <div class="font-500" text="2xl #121212">
      {{ t('key.home.recentlyviewed.title') }}
    </div>
    <div ref="el" mt="8" w="full" overflow-x="auto" class="popular_destination">
      <div class="space-x-4" flex="~ items-center">
        <div v-for="item in recentViewedList" :key="item.azgoId" b="1 solid #00000014" rounded="4" overflow="hidden" w="68" flex-shrink="0" cursor="pointer" hover="bg-#F6F6F6" class="azgo-haptics-feedback" @click="clickCard(item, ENUM_CARD_FROM.RECENT_VIEWED)">
          <div class="relative">
            <van-image h="54" fit="cover" :src="item.coverImageUrl" style="vertical-align: middle;" w="full" flex="shrink-0" />
            <div class="absolute bottom-0" w="full" h="full" style=" background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.67%, #000000 100%);"></div>
            <!-- <div
              v-if="!!item.parentLabelNameList?.length" class="absolute" top="4" left="4" bg="#00000033" rounded="1" h="5" px="1" font="300" text="xs white" flex="~ items-center" style="backdrop-filter: blur(4px)"
            >
              {{ item.parentLabelNameList?.[0] }}
            </div> -->

            <div class="absolute bottom-2 truncate" w="full" px="2" text="sm #FFFFFF">
              <div v-if="item.rating || item.subLabelNameList?.length" mt="1" flex="~ items-center">
                <template v-if="item.rating">
                  <NuxtIcon name="attr-icon:star" text="sm" :style="{ color: 'var(--tc-color-score)' }" />
                  <span text="sm" ml="1" :style="{ color: 'var(--tc-color-score)' }">{{ item.rating }}</span>
                </template>
                <!-- <van-divider v-if="item.rating && item.subLabelNameList?.length" vertical style="padding: 0.1rem; margin: 0 0.25rem;" />
                <span v-if="!!item.subLabelNameList?.length" text="sm white" font="300">{{ item.subLabelNameList?.[0] }}</span> -->
              </div>
            </div>
          </div>
          <div px="4" py="2" h="36">
            <div text="#121212 base" font="500" line-clamp="2">
              {{ item.title }}
            </div>
            <div v-if="item.countryName || item.cityName" mt="2" text="#808080 xs" font="300" flex="~ items-center" bg="#F6F6F6" rounded="1" px="2" py="1" w="fit">
              {{ item.countryName }}
              <template v-if="item.cityName && item.countryName !== item.cityName">
                <van-divider vertical style="padding: 0.1rem 0; margin: 0 0.25rem;" />
                {{ item.cityName }}
              </template>
            </div>
            <div v-if="item.minPriceRecords?.[0]?.minPrice" mt="5" font="500" text="xl #121212 right">
              <span text="xs #B2B2B2" font="300">{{ t('key.attraction.detail.price.from') }}</span> {{ getCurrencyPrice(item.minPriceRecords?.[0]?.minPrice) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!arrivedState.right" class="azgo-hover-feedback absolute bottom-38 right--6 shadow-[0px_0.87px_15px_0px_#71717159]" flex="~ items-center justify-center" w="12" h="12" rounded="full" bg="white" cursor="pointer" @click="x += width / 3 * 2">
      <NuxtIcon name="my-icon:navback" rotate="180" text="xl #121212" />
    </div>
    <div v-if="!arrivedState.left" class="azgo-hover-feedback absolute bottom-38 left--6 shadow-[0px_0.87px_15px_0px_#71717159]" flex="~ items-center justify-center" w="12" h="12" rounded="full" bg="white" cursor="pointer" @click="x -= width / 3 * 2">
      <NuxtIcon name="my-icon:navback" text="xl #121212" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ENUM_CARD_FROM } from '~/enums'

const el = ref<HTMLElement | null>(null)

const { x, arrivedState } = useScroll(el, { behavior: 'smooth' })
const { width } = useElementSize(el)

const { t } = useI18n()
const { recentViewedList, getCurrencyPrice, clickCard } = useSearchResult()
</script>

<style>
.popular_destination::-webkit-scrollbar {
  display: none;
}
</style>
